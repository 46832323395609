import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Button } from '@/components/ui/button';
export default function CustomConnectButton() {
  return <ConnectButton.Custom data-sentry-element="unknown" data-sentry-component="CustomConnectButton" data-sentry-source-file="ConnectButton.tsx">
      {({
      account,
      chain,
      openAccountModal,
      openChainModal,
      openConnectModal,
      authenticationStatus,
      mounted
    }) => {
      // Note: If your app doesn't use authentication, you
      // can remove all 'authenticationStatus' checks
      const ready = mounted && authenticationStatus !== 'loading';
      const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');
      return <div {...!ready && {
        'aria-hidden': true,
        style: {
          opacity: 0,
          pointerEvents: 'none',
          userSelect: 'none'
        }
      }}>
            {(() => {
          if (!connected) {
            return <Button onClick={openConnectModal} className="bg-primary hover:bg-primary/90">
                    Connect Wallet
                  </Button>;
          }
          if (chain.unsupported) {
            return <Button onClick={openChainModal} variant="destructive">
                    Wrong network
                  </Button>;
          }
          return <div className="flex gap-2">
                  <Button onClick={openChainModal} className="flex items-center" variant="outline">
                    {chain.hasIcon && <div className="w-3 h-3 rounded-full overflow-hidden mr-1" style={{
                background: chain.iconBackground
              }}>
                        {chain.iconUrl && <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} className="w-3 h-3" />}
                      </div>}
                    {chain.name}
                  </Button>
                  <Button onClick={openAccountModal} variant="outline">
                    {account.displayName}
                    {account.displayBalance ? ` (${account.displayBalance})` : ''}
                  </Button>
                </div>;
        })()}
          </div>;
    }}
    </ConnectButton.Custom>;
}