'use client';

import { Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';
import * as React from 'react';
import { Button } from '@/components/ui/button';
export function ModeToggle() {
  const {
    theme,
    setTheme
  } = useTheme();
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };
  return <Button variant="outline" size="icon" onClick={toggleTheme} data-sentry-element="Button" data-sentry-component="ModeToggle" data-sentry-source-file="ModeToggle.tsx">
      <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" data-sentry-element="Sun" data-sentry-source-file="ModeToggle.tsx" />
      <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" data-sentry-element="Moon" data-sentry-source-file="ModeToggle.tsx" />
      <span className="sr-only">Toggle theme</span>
    </Button>;
}