'use client';

import { ThemeProvider as NextThemesProvider } from 'next-themes';
import { type ThemeProviderProps } from 'next-themes/dist/types';
import * as React from 'react';
export default function ThemeProvider({
  children,
  ...props
}: ThemeProviderProps) {
  return <NextThemesProvider {...props} data-sentry-element="NextThemesProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="ThemeProvider.tsx">{children}</NextThemesProvider>;
}