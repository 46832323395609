'use client';

import type { ReactNode } from 'react';
import Footer from './Footer';
import Header from './Header';
type LayoutProps = {
  children: ReactNode;
};
const Layout = ({
  children
}: LayoutProps) => {
  return <div className="min-h-screen flex flex-col" data-sentry-component="Layout" data-sentry-source-file="index.tsx">
      <Header data-sentry-element="Header" data-sentry-source-file="index.tsx" />
      <main className="flex-1 flex">{children}</main>
      <Footer data-sentry-element="Footer" data-sentry-source-file="index.tsx" />
    </div>;
};
export default Layout;