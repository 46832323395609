// contexts/LoadingContext.tsx

'use client';

import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useContext, useState } from 'react';
interface LoadingContextType {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);
export const LoadingProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LoadingContext.Provider value={{
      isLoading,
      setIsLoading
    }} data-sentry-element="unknown" data-sentry-component="LoadingProvider" data-sentry-source-file="LoadingContext.tsx">
      {children}
    </LoadingContext.Provider>
  );
};
export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};